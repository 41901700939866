import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브, 앙츠, 코드네이처 등 지분 투자로 중고폰 유통 전문 조인트벤처 ‘레몬코퍼레이션’ 설립",
  "| 통신 및 디바이스 전문 3개사 연합으로 전열 갖춰, 원스톱 중고폰 마켓 선보일 예정",
  "| 사용 중인 핸드폰을 자산화 하여 관리할 수 있도록 설계, 시장 양성화에 힘쓸 계획",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/28/img1.png" style={{ maxWidth: "200px", margin: "auto" }} alt="Figure1" />
      </div>
      <div className="img">
        <img src="/images/press/28/img2.png" style={{ maxWidth: "200px", margin: "auto" }} alt="Figure2" />
      </div>
      <div className="desc">△ 위에서부터 ㈜레몬코퍼레이션 CI 2종</div>
    </div>
    <p>
      <br />
      <br />
      중고폰의 구매ㆍ사용ㆍ처분 등 유통 전 과정을 고객 관점에서 혁신할 전문 기업이 탄생했다.
      <br />
      <br />
      통신 및 중고폰 관련 기업인 △앙츠(ANTZ, 대표 박창진) △스테이지파이브(대표 서상원) △코드네이처(대표 박상훈) 등 3개 기업은 지난
      3월 중고폰 전문 합작회사(조인트벤처)인 ㈜레몬코퍼레이션(이하 레몬코퍼레이션)을 설립, 정식 출범하였다. *3개 기업 설명 하단
      참조 최근 중고폰 거래가 개인간 거래 중개에서 종합 중고물품 거래 플랫폼 중심으로 전환되고 있는 추세인 만큼 시장 흐름에
      발맞추는 동시에 절대 강자가 없는 중고폰 시장에서 신뢰와 편리성의 대표주자로 자리매김한다는 목표다.
      <br />
      <br />
      현재 국내 중고폰 시장 규모는 업계 추산 1,000만대, 거래금액은 2조 정도로 추산된다. 연간 휴대폰 판매량 약 1,800만 대 가운데
      60~70%가량이 중고폰으로 유통되는 셈이다. 해당 시장은 최근 점점 커져가는 자급제폰 수요 및 길어지는 디바이스 수명에 맞춰 지속
      성장할 것으로 예상된다. 그러나 중고폰을 구매하는 소비자 입장에서는 중고폰 거래방식에 대한 불편함과 업체에 대한 신뢰 부족,
      책정되는 가격에 대한 객관적 기준 미비 등의 벽에 부딪혀 왔다. 또한, 개인정보를 담고 있는 스마트폰 특성 상 검증되지 않은
      중고폰 유통채널을 거치게 될 경우 도난/분실폰 유통 등이 우려되며, 피해 발생 시 보상도 어려운 상황이다.
      <br />
      <br />
      레몬코퍼레이션은 이와 같은 고객 페인포인트를 혁신하고자, 출범과 동시에 중고폰의 품질ㆍ가격ㆍ거래 등 전 요소에서 고객이
      신뢰할 수 있는 자체 플랫폼 및 시스템 기획에 돌입했다. 이를 통해 중고폰 거래 활성화를 막는 요소로 꼽히는 개인 정보 유출에
      관한 우려, 거래 상대방에 대한 정보 부족, 제품 품질 및 거래 가격에 대한 불신 등의 원인을 불식시키고 고객 접근성 및 편리성을
      혁신적인 방법으로 해결할 예정이다.
      <br />
      <br />
      구체적으로는 수급 즉시 검수 및 보상 지급이 가능한 시스템을 골자로 전문 서비스 개발 및 정책 운영에 나선다는 계획이다.
      레몬코퍼레이션은 매입 및 공급 라인의 안정화와 리퍼비시 및 중고폰 처분 관련 서비스를 제공하는 B2B 사업은 물론이고, 이를
      기반으로 플랫폼화를 통한 마켓운영에 중점을 둔 사업을 전개할 계획이다.
      <br />
      <br />
      이를 위해 각 투자사는 지분 출자와 함께 자원 투자 및 보유 인프라를 활용하기로 했다. 3개사의 중고폰 회수 및
      검수ㆍ가공(리퍼비시, 초기 불량품을 재정비) 역량, 통신사 및 제조사 제휴 노하우를 바탕으로 한 사업 상 시너지가 이루어질
      전망이다.
      <br />
      <br />
      한편, 레몬코퍼레이션은 김준형 前 스테이지파이브 부사장이 대표를 맡아 전체 사업을 이끌 계획이다. 김 부사장은 2021년
      스테이지파이브 부사장으로 합류하여 신사업 부문장을 맡아왔으며, 2020년 LG유플러스 5G 서비스 그룹장(상무), 2014~2015년
      LG유플러스 디바이스 담당 등을 역임한 통신 사업 전문가이다.
      <br />
      <br />
      김 대표는 “이미 중고폰 사업에 전문성을 가진 기업이 똘똘 뭉쳐 합작사 설립을 한 만큼 중고폰시장의 ‘메기’(Catficsh Effect)를
      꿈꾸고 있다. 무엇보다 품질 및 가격, 고객 편리성을 모두 잡는 신뢰받는 중고폰 유통 플랫폼이 되는 것이 목표이다. 우리
      사업모델이 중고폰 시장의 양성화와 더불어 자원 재활용을 통한 ESG 가치 실현에도 이바지하길 기대한다”며, “그동안 음성적
      이미지였던 중고폰에 대한 인식을 환기시키고, 해당 산업에 대한 정책적 개선방안도 적극적으로 목소리 낼 수 있도록 하겠다”고
      밝혔다.
      <br />
      <br />
      ■ 국내외 중고폰 시장 현황 국내외 중고 스마트폰 시장 전망은 밝다.
      <br />
      <br />
      시장조사업체 IDC에 따르면 전 세계 중고 스마트폰 시장은 2018년부터 향후 5년간 연평균 13.6%의 성장률을 기록하고 있다. 2023년
      출하량은 3억 3290만대로 예상, 그 가치만 670억달러(약 88조원)에 달할 것으로 전망했다. 중고폰 빅데이터 분석업체 유피엠에
      따르면 2018년 기준 국내 B2C 중고폰 거래량은 483만 5천여대, 매출 기준 약 8550억원이다. 이를 기반으로 업계에서는 국내 중고폰
      시장 전체 규모를 약 2조원 수준으로 추정한다.
      <br />
      <br />
      국내 B2C 중고폰 플랫폼 시장은 2000년 런칭한 세티즌을 시작으로 소녀폰, 에코폰, 폰사요몰, 셀잇, 중고나라-중고폰, 바른폰, 민팃,
      리폰 등이 있다. 중고 스마트폰 O2O(online to offline) 리폰, 중고폰 견적 비교 서비스(폰가비)와 B2B 유통 시세 분석
      시스템(중가비)을 운영하는 업스테어스 등 스타트업도 속속 생겨나고 있다. 이들 거래 플랫폼은 표준화된 시세 정보, 품질 보증,
      안전결제, 간편거래 등의 장점을 앞세워 중고폰 거래 시장 활성화에 나서는 중이다.
      <br />
      <br />■ 합작 참여 기업 소개
      <br />
      <br />
      스테이지파이브는 국내 MNO 및 MVNO 통신 비대면 가입 플랫폼 ‘핀다이렉트샵’의 운영사이다. 플랫폼 내 중고폰을 비롯한
      통신디바이스 및 MVNO 요금제 상품판매에 주력하고 있다.
      <br />
      <br />
      앙츠(ANTZ)는 애플(Apple) 공식 인증 서비스 센터를 운영하는 업체로 스마트폰 A/S 및 모바일, IT 솔루션 전문 회사이다. 전국 26개
      지점을 두고 있으며, 아이폰에 특화된 노하우를 기반으로 스마트폰 검수 및 수리 기술력과 전문 상담 인력, 오프라인 유통 거점 등을
      갖췄다.
      <br />
      <br />
      코드네이처는 신재생에너지, 스마트시티, 종합유통 관련 기업이다. 특히 국내 MVNO 통신 및 중고폰 사업 운영 노하우, 국내외를
      아우른 중고폰 사업 관리 역량을 보유했다.
    </p>
  </div>
)

const press27 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)레몬코퍼레이션"
      // writer="커뮤니케이션팀 pr@stagefive.com "
      date="2022년 03월 14일"
      // pageInfo="*총 2매 / 첨부사진 2매"
      title="중고폰 시장 양성화하기 위한 합작사 나왔다… ”㈜레몬코퍼레이션, ICT 활용해 품질, 가격, 거래 편리성, ESG까지 모두 잡을 것”"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press27
